<script>
export default {
  name: 'TextWithTooltip',
  props: {
    title: {
      type: String,
    },
    activator: {
      type: String,
    },
    maxWidth: {
      type: [Number, String],
      default: 'auto',
    },
    color: {
      type: String,
    },
  },
};
</script>

<template>
  <v-tooltip top :max-width="maxWidth" :color="color" content-class="content px-4 py-2">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" v-html="activator"></span>
    </template>
    <span v-html="title"></span>
  </v-tooltip>
</template>

<style scoped lang="scss">
.content {
  opacity: 1 !important;
  font-size: 12px;
  line-height: 16px;
  font-weight: 100;
}
</style>
